const { addData } = require("./Indexdb/indexedDB");

var myFunction = {}


myFunction.sendReactNativeMessage = function (ary) {
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(ary));
}



myFunction.reloadMathjax = async function () {
    if (window.MathJax && window.MathJax.Hub) {
        // Queue the typesetting
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);

        // Optionally re-run typesetting after a short delay
        setTimeout(() => {
            window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
        }, 50);
    } else {
        console.error('MathJax is not loaded or accessible.');
    }
};

myFunction.Adddatadb = async (data, where) => {
    await addData(data, where);
}

myFunction.countmark = (data) => {
    const marksCount = data.reduce((acc, item) => {
        const mark = item.marks;
        acc[mark] = (acc[mark] || 0) + 1;
        return acc;
    }, {});

    // Convert the result into the desired format
    const result = Object.entries(marksCount).map(([key, value]) => ({ [key]: value }));
    return result
}
myFunction.totalsum = (data) => {
    const sum = data.reduce((acc, obj) => {
        const key = parseInt(Object.keys(obj)[0]); // Extract the key and convert it to a number
        const value = Object.values(obj)[0]; // Extract the value
        return acc + (key * value); // Multiply key and value, then add to the accumulator
    }, 0); // Initialize the accumulator to 0
    return sum
}

myFunction.checkPWA = () => {
    const standalone = window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone;
    return standalone;
};

module.exports = myFunction;