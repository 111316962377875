
import { checkPWA } from './Helper';
import Mainroutes from './Routes';
import { getUA, isMobile, deviceDetect, osName, browserVersion, browserName } from 'react-device-detect';


function App() {
	// const data = {}


	// console.log(getUA)
	// if (checkPWA()) {

	// } else {
	// 	data.user_agent = getUA
	// 	// data.device_type
	// }

	// const device = deviceDetect()
	// console.log(data, device)

	if (window?.isApp) {
		alert("isapp work")
	}



	return (
		<>
			<Mainroutes />
		</>
	);
}

export default App;
